import React, {useState , useContext} from 'react';
import {RiHome5Line } from 'react-icons/ri'
import { GoChevronUp, GoChevronDown } from "react-icons/go";

//importig headless ui
import {Menu} from '@headlessui/react'

//import the context 
import { HouseContext } from './HouseContext';

const PropertyDropdown = () => {
  const {property, setProperty, properties} = useContext(HouseContext);
  const [isOpen, setIsOpen] = useState(false);

  return (
  <Menu as='div'  className='dropdown relative'>
    <Menu.Button onClick={() => setIsOpen(!isOpen)} className='dropdown-btn w-full text-left'>
      <RiHome5Line  className='dropdown-icon-primary'/>
      <div>
        <div className='text-[15px] font-medium leading-tight '>
          {property}
        </div>
        <div  className='text-[13px]'>Select Your Place</div>
      </div>
      {
          isOpen ? (
            <GoChevronUp  className='dropdown-icon-secondary'/>
          ):
          (
            <GoChevronDown className='dropdown-icon-secondary' />
          )
        }
    </Menu.Button>
    <Menu.Items className='dropdown-menu'>
      {properties.map((property, index) => {
        return (
          <Menu.Item 
          
          onClick={()=>setProperty(property)} className='cursor-pointer hover:text-violet-700' as='li' key={index}>
            {property}
          </Menu.Item>
        )
      })

      }
    </Menu.Items>
  </Menu>
  
  );
};

export default PropertyDropdown;
