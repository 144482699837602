import React from 'react';

const Footer = () => {
  return( 
  <footer data-aos="fade-down" className='bg-black text-center py-8 text-white'>
    <div className='container mx-auto' >
      <p >Created By Solution Tech Company</p>
    </div>
  </footer>
);
};

export default Footer;
