import React, { useEffect } from 'react';


import { Routes,Route } from 'react-router-dom';

import Header from './components/Header'
import Footer from './components/Footer'
import Home from './pages/Home'
import PropertyDetails from './pages/PropertyDetails'
import HouseList from './components/HouseList';
import AOS from 'aos';
import 'aos/dist/aos.css';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
const App = () => {
  useEffect(() => {
    AOS.init({
      duration: 800,
      easing:'ease-in-sine',
      delay: 150,
      offset: 100,
      once: false,
      mirror: true, // Whether animation should happen only once - while scrolling down
    });
    AOS.refresh();
  }, []);
  return(
    <div className='max-w-[1440px] mx-auto bg-white '>
      <Header/>
      <Routes>
          <Route path='/' element={<Home/>} />
          <Route path='/property/:id' element={<PropertyDetails/>}/>
          <Route path='/login' element={<LoginPage/>} />
          <Route path='/register' element={<RegisterPage/>} />
      </Routes>

      <Footer/>
    
    </div>
  ); 
};

export default App;
