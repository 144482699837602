import React from 'react';

import { Link } from 'react-router-dom';
import Logo from '../assets/img/logo.svg'

const Header = () => {
  return (
  <header className='py-6 mb-12 border-b'>
    <div className='container mx-auto flex justify-between items-center'> 
      <Link to='/'>
      <img src={Logo} alt='Logo'/>
      </Link>
      <div className='flex items-center gap-6'>
        <Link className='hover:text-violet-900 transition' to='/login'>Log in</Link>
        <Link  data-aos="zoom-in" className='bg-violet-700 hover:bg-violet-800 px-4 py-3 rounded-lg text-white' to='/register'>Sign up</Link>
      </div>
    </div>
  </header>
  );
};

export default Header;
