import React, { useContext } from 'react';
import CountryDropdown from './CountryDropdown'
import PropertyDropdown from './PropertyDropdown'
import PriceRangeDropdown from './PriceRangeDropdown'

import {RiSearch2Line} from 'react-icons/ri' 
import { HouseContext } from './HouseContext';



const Search = () => {
  const {HandleClick} = useContext(HouseContext)
  const {houses} = useContext(HouseContext)
  return( 
  <div className='px-[30px] py-6 max-w-[1170px] mx-auto flex flex-col lg:flex-row justify-between gap-4 lg:gap-x-3 relative lg:-top-4 lg:shadow-1 
  lg:backdrop-blur lg:bg-transparent rounded-lg z-50'  data-aos="fade-up" >
    <CountryDropdown />
    <PropertyDropdown />
    <PriceRangeDropdown />
    <button data-aos="fade-up"   data-aos-delay="50"  onClick={HandleClick} className='bg-violet-700 hover:bg-violet-800  transition lg:max-w-[162px] w-full flex items-center justify-center h-16 rounded-lg text-white text-lg z-10'>
      <RiSearch2Line /> 
    </button>
  </div>
  );
};

export default Search;
