import React, { useContext } from 'react';
import { HouseContext } from './HouseContext';
import { ImSpinner2 } from 'react-icons/im'
import { Link } from 'react-router-dom';
import House from '../components/House';

const HouseList = (house) => {
  const { houses, loading } = useContext(HouseContext)



  //if Loading is true

  if(loading){
    return (
      <ImSpinner2 className='mx-auto animate-spin text-4xl text-violet-700 mt-[200px]'/>

    )
  }

  if(houses.length < 1){
    return (
      <div className='text-[25px] text-center text-gray-500 mt-48'>Sorry, nothing is found !</div>
    )
  }
  return (
  <section className='mb-20 '>
    <div className='container mx-auto'>
      <div className='grid md:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-14 z-0'
     
      data-aos-delay={house.aosDelay}
      >
        {houses.map((house, index)=>{
            return (
              <Link to={`/property/${house.id}`} key={index}
              data-aos="fade-up"
              data-aos-delay={house.aosDelay} 
              className='z-0'
              >
                    <House
                    className='z-0'
                    house={house}/>
              </Link>
            )
        })
        }
      </div>
    </div>
  </section>
);
};

export default HouseList;
