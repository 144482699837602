import React from 'react';
import Image from '../assets/img/house-banner.png'
import Search from '../components/Search'

const Banner = () => {
  return (
  <section className='h-full max-h-[640px] mb-8 xl:mb-24'>
    <div className='flex flex-col lg:flex-row'>
      <div className='lg:ml-8 xl:ml-[135px] flex flex-col items-center lg:items-start text-center lg:text-left justify-center flex-1 px-4 lg:px-0'>
        <h1 data-aos="fade-up" data-aos-delay="100" className='text-4xl lg:text-[58px] font-semibold leading-none mb-6'><span className='text-violet-700'>Rent</span> Your Dream <span data-aos="fade-up" data-aos-delay="700">House With Us!</span> </h1>
        <p className='max-w-[480px] mb-8'>
        JavaScript is one of the three core web development technologies, alongside
        Hypertext Markup Language (HTML) and Cascading Style Sheets (CSS).
        While HTML structures the webpage content and CSS handles styling and
        layout, JavaScript adds interactivity, logic, and 
        </p>
      </div>
      <div className='hidden flex-1 lg:flex items-end justify-end'>
        <img src={Image} alt='banner'/>
      </div>
    </div>
    <Search />
  </section>);
};

export default Banner;
