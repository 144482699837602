import React from 'react'
import { Link } from 'react-router-dom'
import { BiUser } from 'react-icons/bi'
import { AiOutlineUnlock, AiOutlineLock } from 'react-icons/ai'
const RegisterPage = () => {
  return (
    <div className='text-gray-700 h-[100vh] flex items-center justify-center bg-cover relative ' >
    <div className='bg-transparent border rounded-md p-8 shadow-lg backdrop-filter backdrop-blur-sm bg-opacity-30 relative m-2 mt-auto mb-auto'>
        <h1 className='text-4xl text-gray-700 font-bold text-center mb-6 '>Register</h1>
        <form> 
            <div className='relative my-4'>
            <input className='border border-gray-300 focus:border-violet-700 outline-none rounded w-full px-4 h-14 text-sm text-gray-400' type='text' placeholder='Email' />
            <BiUser data-aos="fade-left" className='top-4 absolute flex right-4 text-2xl'/>
                <label htmlFor=''>Your Email</label>
                
            </div>
            <div className='relative my-4'>
                <input className='border border-gray-300 focus:border-violet-700 outline-none rounded w-full px-4 h-14 text-sm text-gray-400' type='password' placeholder='Password' />
                <AiOutlineLock data-aos="fade-left"  className='top-4 absolute flex right-4 text-2xl'/>
                <label htmlFor=''>Your Passowrd</label>
            </div>
            <div className='relative my-4'>
                <input className='border border-gray-300 focus:border-violet-700 outline-none rounded w-full px-4 h-14 text-sm text-gray-400' type='password' placeholder='Confirm Password' />
                <AiOutlineLock data-aos="fade-left"  className='top-4 absolute flex right-4 text-2xl'/>
                <label htmlFor=''>Confirm Passowrd</label>
            </div>
            <div className='flex items-center justify-between mb-6' >
                <div className='flex items-center gap-2'>
                    <input type='checkbox'/>
                    <label>Remember me</label>
                </div>
             <a href='' className='text-violet-800'>Forgot Password?</a>
            </div> 

            <button type='submit' className='bg-violet-700 hover:bg-violet-800 rounded text-white p-4 text-sm w-full transition duration-300 mb-6'>Register</button>
           
        <div className='flex items-center gap-3'>
                <span>Already have an account?   </span><Link to='/login' className='text-violet-600 cursor-pointer hover:text-violet-800'>Login</Link>
        </div>
        </form>
    </div>
</div>  
  )
}

export default RegisterPage
