import React from 'react';
import { BiBed, BiBath, BiArea} from 'react-icons/bi'
const House = ({ house }) => {
  const {image,  type, country, address, bedrooms, bathrooms, surface, price} =  house;
  return (
  <div  className='bg-white shadow-1 p-5 rounded-lg rounded-tl-[90px] w-full max-w-[352px] mx-auto
  cursor-pointer hover:shadow-2xl transition duration-200'>
    <img className='mb-8' src={image} alt='house' />
    <div className='flex gap-x-2 text-sm'>
      <div className='bg-green-500 rounded-full text-white px-3'>{type}</div>
      <div className='bg-violet-500 rounded-full text-white px-3'>{country}</div>
    </div>
    <div className='max-w-[260px] font-semibold text-lg'>
      {address}
      <div className='flex gap-x-2 my-4'> 
        <div className='flex gap-1 items-center text-gray-600'>
          <div className='text-[20px]'><BiBed/></div>
          <div>{bedrooms}</div>
        </div>
        <div className='flex gap-1 items-center text-gray-600'>
          <div className='text-[20px]'><BiBath/></div>
          <div>{bathrooms}</div>
        </div>
        <div className='flex gap-1 items-center text-gray-600'>
          <div className='text-[20px]'><BiArea/></div>
          <div>{surface}</div>
        </div>
      </div>
    <div className='text-violet-600 mb-4 text-lg font-semibold'>{new Intl.NumberFormat().format (price)}Tsh</div>
    </div>
  </div>
  
);
};

export default House;
