import React, {useState, useEffect, useContext} from 'react';
import {RiMapPinLine, RiArrowDownLine, RiArrowUpLine, RiMapLine} from 'react-icons/ri'
import { GoChevronUp, GoChevronDown } from "react-icons/go";

//importig headless ui
import {Menu} from '@headlessui/react'

//import the context 
import { HouseContext } from './HouseContext';

const CountryDropdown = () => {
  const {country, setCountry, countries} = useContext(HouseContext);
  const [isOpen, setIsOpen] = useState(false);
  return (
  <Menu as='div'  className='dropdown relative'>
    <Menu.Button onClick={() => setIsOpen(!isOpen)} className='dropdown-btn w-full text-left'>
      <RiMapPinLine  className='dropdown-icon-primary'/>
      <div>
        <div className='text-[15px] font-medium leading-tight '>
          {country}
        </div>
        <div className='text-[13px]'>Select Your Place</div>
      </div>
      {
          isOpen ? (
            <GoChevronUp  className='dropdown-icon-secondary'/>
          ):
          (
            <GoChevronDown className='dropdown-icon-secondary' />
          )
        }
    </Menu.Button>
    <Menu.Items className='dropdown-menu z-50'>
      {countries.map((country, index) => {
        return (
          <Menu.Item 
          onClick={()=>setCountry(country)} className='cursor-pointer hover:text-violet-700 ' as='li' key={index}>
            {country}
          </Menu.Item>
        )
      })

      }
    </Menu.Items>
  </Menu>
  
  );
};

export default CountryDropdown;
