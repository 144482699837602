import React from 'react';

import { housesData } from '../data';
import { useParams } from 'react-router-dom';
import { BiBath, BiArea, BiBed } from 'react-icons/bi';
import { Link } from 'react-router-dom';

const PropertyDetails = () => {
  const {id} = useParams();
  
  const house = housesData.find((house) =>{
    return house.id === parseInt(id)
  })

  return (
    <section>
      <div className='container mx-auto mb-14'>
        <div className='flex flex-col lg:flex-row lg:items-center lg:justify-between'>
          <div>
            <h2 className='text-2xl font-semibold'>{house.name}</h2>
            <h3 className='text-lg mb-4'>{house.address}</h3>
          </div>
          <div className='mb-4 lg:mb-0 flex gap-x-2 text-sm'>
            <div className='bg-green-500 rounded-full px-3 text-white'>{house.type}</div>
            <div className='bg-violet-500 rounded-full px-3 text-white'>{house.country}</div>
          </div>
          <div data-aos="fade-left" className='text-3xl font-semibold text-violet-600'>{new Intl.NumberFormat().format (house.price)}Tsh </div>
        </div>
        <div className='flex flex-col items-start gap-8 lg:flex-row'>
          <div className='max-w-[768px]'>
            <div className='mb-8'>
              <img data-aos="zoom-in" src={house.imageLg} alt='house'/>
            </div>
            <div className='flex gap-x-6 text-violet-500 mb-6'>
              <div className='flex gap-x-2 items-center'>
                  <BiBed className='text-2xl'/>
              <div>{house.bedrooms}</div>
              </div>
              <div className='flex gap-x-2 items-center'>
                  <BiBath className='text-2xl'/>
              <div>{house.bathrooms}</div>
              </div>
              <div className='flex gap-x-2 items-center'>
                  <BiArea className='text-2xl'/>
              <div>{house.surface}</div>
              </div>
            </div>
            <div>{house.description}</div>
          </div>
          <div className='flex-1 bg-white rounded-lg border-gray-300 px-6 py-8 w-full border'>
            <div className='flex items-center gap-x-4 mb-8'>
              <div className='h-20 w-20 p-1 border border-r-2  border-violet-500 rounded-full'>
                <img data-aos="zoom-in" src={house.agent.image} alt='agent' />
              </div>
              <div>
                <div className='font-semibold text-lg'>{house.agent.name}</div>
                <Link to='' className='text-violet-500 text-sm'>
                View Listing
                </Link>
              </div>
            </div>
            {/* form */}
            <form className='flex flex-col gap-y-4'>
              <input className='border border-gray-300 focus:border-violet-700 outline-none rounded w-full px-4 h-14 text-sm text-gray-400' type='text' placeholder='Name' />
              <input  className='border border-gray-300 focus:border-violet-700 outline-none rounded w-full px-4 h-14 text-sm text-gray-400' type='text' placeholder='Email*' />
              <input className='border border-gray-300 focus:border-violet-700 outline-none rounded w-full px-4 h-14 text-sm text-gray-400' type='text' placeholder='Phone*' />
              
              <textarea className='border border-gray-300 focus:border-violet-700 outline-none resize-none w-full rounded p-4 h-36 text-sm text-gray-400' placeholder='Message*' defaultValue='Hello am interested in Modern Apartment '></textarea>
              <div className='flex gap-x-2'>
                <button className='bg-violet-700 hover:bg-violet-800 rounded text-white p-4 text-sm w-full transition duration-150'>Send Message</button>
                <button className='border border-violet-700 text-violet-700 hover:text-violet-500 hover:border-violet-500 rounded p-4 w-full transition duration-150'>Call</button>
              </div>
            </form>
          </div>
        </div>
      </div>

    </section>
  );
};

export default PropertyDetails;
